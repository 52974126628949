
















































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType
} from '@vue/composition-api';
import Store from '@/store';
import { i18nTxt } from '@/commons/i18n';
import { HistoryComment } from '@/models/comment-info';
import { LikeComment } from '@/components/atoms/index';
import { convertDate } from '@/services/common/utils';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import AlertDialog from '@/dialogs/AlertDialog.vue';
import { DialogStoreOption } from '@/store/dialog';
import { LCS_TYPES } from '@/commons/constants/lcs-types';
import {
  ChaptersApiService,
  RENT_WITH_COIN,
  RENT_WITH_GAUGE,
  RENT_WITH_TICKET
} from '@/services/contents/chapters.api';
import { Chapter, ContentInfo } from '@/models';
import {
  ChapterHelperService,
  gotoChapter
} from '@/services';
import { SalesStateHelperService } from '@/services/contents/sales.state.helper';
import {
  PurchaseButtons,
  TYPE_PURCHASE_BUTTON,
  TYPE_SALES_STATE
} from '@/commons/constants/sales-types';
import router from '@/router';
import { ICON_SELECT_PURCHASE } from '@/commons/constants/icon-types';
import { CoinService } from '@/services/coin/coin.api';
import { fbq } from '@/services/common/facebook-pixel.service';
import { ContentsApiService } from '@/services/contents/contents.api';
import UseCoinConfirmDialog from '@/dialogs/UseCoinConfirmDialog.vue';

export default defineComponent({
  name: 'CommentHistory',
  components: {
    LikeComment
  },
  props: {
    commentsList: {
      type: Array as PropType<Array<HistoryComment>>
    }
  },
  setup(_props, { root }) {
    const { $q } = root;
    const { dispatch, getters } = Store;
    const isAuth: ComputedRef<boolean> = computed(
      () => getters['AuthStore/isAuth']
    );
    const deleteComment = (contentType: string, commentId: string) => {
      $q.dialog({
        component: ConfirmDialog,
        componentProps: {
          title: '',
          message: i18nTxt('are_you_sure_you_want_to_delete_the_comments'),
          ok: i18nTxt('ok'),
          cancel: i18nTxt('cancel')
        }
      }).onOk(async (res) => {
        dispatch('CommentStore/deleteComment', { contentType, commentId });
      });
    };

    const likeComment = (contentType: string, commentId: string) => {
      dispatch('CommentStore/likeComment', { contentType, commentId });
    };

    const onCommentTitleClick = async (item: HistoryComment) => {
      const { contentId, contentType, chapterId } = item;
      try {
        const result = await ChaptersApiService.getChapter({
          contentType,
          contentId,
          chapterId,
          chapterFileSalesType: 'product'
        });

        if(!result.content) return;
        popPurchaseDialog({
          chapter: new Chapter(result.chapter),
          content: new ContentInfo(result.content)
        });
      }catch(e){
        if(e.result.code === 303){
          $q.dialog({
            component: AlertDialog,
            componentProps: {
              title: '',
              message: i18nTxt('chapter_aborted'),
              ok: i18nTxt('ok')
            }
          }).onOk(async (res) => {
          
          });
        }else if(e.result.code === 404){
          $q.dialog({
            component: AlertDialog,
            componentProps: {
              title: '',
              message: e.result.message,
              ok: i18nTxt('ok')
            }
          }).onOk(async (res) => {
          
          });
        }
      }
    };

    const popPurchaseDialog = ({ chapter, content }) => {
      const options: DialogStoreOption = {
        component: () => import('@/dialogs/PurchaseDialog.vue'),
        param: {
          prevPage: LCS_TYPES.COMMENT_HISTORY,
          parent: root,
          content,
          chapter
        }
      };
      dispatch('DialogStore/dialog', options).then((res) => {
        if (res) {
          sendPurchaseDialog(res);
        }
      });
    };

    const rentWith = async (
      rentType: string,
      contentType: string | undefined,
      contentId: number | undefined,
      chapter: Chapter
    ) => {
      try {
        if (contentType && contentId && chapter.id) {
          const { transaction } = await CoinService.getTransaction();
          const { rentalPrice } = chapter.salesConfig;
          const params = {
            token: transaction.token
          };
          if (rentalPrice !== undefined) {
            params['rentalPrice'] = rentalPrice;
          }
          await ChaptersApiService.rentWith(rentType, {
            contentType,
            contentId,
            chapterId: chapter.id,
            params
          });
          gotoChapter({
            contentType,
            contentId,
            chapterId: chapter.id,
            chapterFileSalesType: 'product'
          });
        }
      } catch (e) {
        //
      }
    };

    const unlock = async (
      contentType: string | undefined,
      contentId: number | undefined,
      chapter: Chapter
    ) => {
      try {
        if (contentType && contentId && chapter.id) {
          const { transaction } = await CoinService.getTransaction();
          const { coinBackPrice } = chapter.salesConfig;
          const params = {
            token: transaction.token,
            price: chapter.salesConfig.getPrice() + ''
          };
          if (coinBackPrice !== undefined) {
            params['coinBackPrice'] = coinBackPrice.toString();
          }
          await ChaptersApiService.unlock({
            contentType,
            contentId,
            chapterId: chapter.id,
            params
          });
          gotoChapter({
            contentType,
            contentId,
            chapterId: chapter.id,
            chapterFileSalesType: 'product'
          });
        }
      } catch (e) {
        //
      }
    };

    const sendPurchaseDialog = async ({
      button,
      chapter,
      content
    }: {
      button: PurchaseButtons;
      chapter: Chapter;
      content: ContentInfo;
    }) => {
      const { checkFree, checkSignIn } = SalesStateHelperService;
      const { type } = button;
      const { salesConfig } = chapter;
      const { inventory } = content;

      const result = await ContentsApiService.getContentsByUnit({
        contentType: content.type,
        contentId: content.id,
        chapterUnit: content.chapterUnit
      });
      const contentInfo = new ContentInfo(result[content.chapterUnit].content);
      if (type === ICON_SELECT_PURCHASE.type) {
        popSelectPurchase(contentInfo, chapter);
      } else {
        checkFree(salesConfig)
          .then(checkSignIn.bind(null, isAuth.value))
          .then(() => {
            const contentType = content.type;
            const contentId = content.id;
            if (content && inventory) {
              switch (type) {
                case TYPE_PURCHASE_BUTTON.BUY_COIN.type: // 코인으로 구입
                  if (
                    inventory.getCoinTotalAmount() >= salesConfig.getPrice()
                  ) {
                    if (root['$isJa']) {
                      confirmCoinUse(content, chapter, true);
                    } else {
                      unlock(contentType, contentId, chapter);
                      fbq.coinUsed();
                    }
                  } else {
                    router.push('/coinpurchase');
                  }
                  break;
                case TYPE_PURCHASE_BUTTON.RENT_COIN.type: // 코인으로 대여
                  if (
                    salesConfig.rentalPrice !== undefined &&
                    salesConfig.rentalPrice >= 0
                  ) {
                    if (
                      inventory.getCoinTotalAmount() >= salesConfig.rentalPrice
                    ) {
                      if (root['$isJa']) {
                        confirmCoinUse(content, chapter, false);
                      } else {
                        `rentWith ${RENT_WITH_COIN}: ${contentType} / ${contentId} / ${chapter}`;
                        rentWith(
                          RENT_WITH_COIN,
                          contentType,
                          contentId,
                          chapter
                        );
                        fbq.coinUsed();
                      }
                    } else {
                      router.push('/coinpurchase');
                    }
                  }
                  break;
                case TYPE_PURCHASE_BUTTON.RENT_TICKET.type: // 티켓으로 대여
                  rentWith(RENT_WITH_TICKET, contentType, contentId, chapter);
                  break;
                case TYPE_PURCHASE_BUTTON.PREVIEW.type: // 미리보기
                  if (!chapter.id) return;
                  gotoChapter({
                    contentType,
                    contentId,
                    chapterId: chapter.id,
                    chapterFileSalesType: 'product'
                  });
                  break;
                case TYPE_PURCHASE_BUTTON.READ_NOW.type: // 지금읽기(filled gauge)
                  rentWith(RENT_WITH_GAUGE, contentType, contentId, chapter);
                  break;
              }
            }
          })
          .catch(({ action }) => {
            switch (action) {
              case TYPE_SALES_STATE.GOTO_VIEWER:
                if (!chapter.id) return;
                gotoChapter({
                  contentType: content.type,
                  contentId: content.id,
                  chapterId: chapter.id,
                  chapterFileSalesType: 'product'
                });
                break;
              case TYPE_SALES_STATE.GOTO_LOGIN:
                router.push('/login');
                break;
            }
          });
      }
    };

    const popSelectPurchase = (content: ContentInfo, chapter: Chapter) => {
      // 무료화 제외
      const removed = ChapterHelperService.getSelectPurchaseChapters(
        content.chapters
      );
      if (removed && removed.length > 0) {
        const options: DialogStoreOption = {
          component: () => import('@/dialogs/SelectPurchase.vue'),
          param: {
            prevPage: LCS_TYPES.COMMENT_HISTORY,
            parent: root,
            contentType: content.type,
            contentId: content.id,
            data: removed,
            chapter: chapter,
            inventory: content.inventory,
          }
        };
        dispatch('DialogStore/dialog', options);
      } else {
        root.$q.dialog({
          component: ConfirmDialog,
          componentProps: {
            title: '',
            message: root.$t('unable_purchase_contents'),
            ok: root.$t('ok')
          }
        });
      }
    };

    /**
     * 코인 사용 확인 dialog (only japan)
     */
    const confirmCoinUse = (content, chapter, isBuy) => {
      const originMsg = root
        .$t('confirm_coin_use')
        .toString()
        .split('\n')
        .join('<br />');
      const salesPrice = chapter.salesConfig.getPrice();
      const { rentalPrice } = chapter.salesConfig;
      const convertMsg = originMsg.replace(
        '%@',
        `${isBuy ? salesPrice : rentalPrice}coin`
      );
      $q.dialog({
        component: UseCoinConfirmDialog,
        componentProps: {
          title: '',
          message: convertMsg,
          cancel: i18nTxt('cancel'),
          ok: i18nTxt('ok')
        }
      }).onOk(() => {
        if (isBuy) {
          unlock(content.type, content.id, chapter);
        } else {
          rentWith(RENT_WITH_COIN, content.type, content.id, chapter);
        }
        fbq.coinUsed();
      });
    };

    return {
      convertDate,
      deleteComment,
      likeComment,
      onCommentTitleClick
    };
  }
});
